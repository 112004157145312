import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductListContainer as SourceProductListContainer,
} from 'SourceComponent/ProductList/ProductList.container';
import { scrollToTop } from 'Util/Browser';
import { RootState } from 'Util/Store/Store.type';
import { setQueryParams } from 'Util/Url';

import { ProductListContainerProps } from './ProductList.type';
/** @namespace Pwa/Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/ProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
    _getIsInfiniteLoaderEnabled(): boolean { // disable infinite scroll on mobile
        // const { isInfiniteLoaderEnabled = false, device } = this.props;
        // const {
        // isInfiniteLoaderEnabled: defaultIsInfiniteLoaderEnabled = false,
        // } = ProductListContainer.defaultProps;

        // allow scroll and mobile
        // if (device.isMobile) {
        //     return isInfiniteLoaderEnabled || defaultIsInfiniteLoaderEnabled;
        // }

        return false;
    }

    componentDidUpdate(prevProps: ProductListContainerProps): void {
        const {
            sort,
            search,
            filter,
            pages,
            isPlp,
        } = this.props;

        const {
            sort: prevSort,
            search: prevSearch,
            filter: prevFilter,
            location: prevLocation,
        } = prevProps;

        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();
        const isProductListUpdated = JSON.stringify(filter) !== JSON.stringify(prevFilter)
                                    || currentPage !== prevPage;

        const isSortUpdated = JSON.stringify(sort) !== JSON.stringify(prevSort);

        if (isProductListUpdated || isSortUpdated) {
            window.isPrefetchValueUsed = false;
        }

        // prevents requestPage() fired twice on Mobile PLP with enabled infinite scroll
        if (this._getIsInfiniteLoaderEnabled() && isPlp && !(isProductListUpdated || isSortUpdated)) {
            return;
        }

        if (search !== prevSearch || isProductListUpdated) {
            this.requestPage(this._getPageFromUrl());
        }

        if (isSortUpdated) {
            const isOnlySortUpdated = true;
            this.requestPage(this._getPageFromUrl(), false, isOnlySortUpdated);
        }
    }

    updatePage(pageNumber: number): void {
        const { location, history } = this.props;

        setQueryParams({
            page: pageNumber === 1 ? '' : String(pageNumber),
        }, location, history);

        if (!this._getIsInfiniteLoaderEnabled()) {
            scrollToTop();
        }
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        ProductListContainer as unknown as React.ComponentType<
        RouteComponentProps & ProductListContainerProps
        >,
    ),
);
