import {
    INTERSECTION_RATIO as SOURCE_INTERSECTION_RATIO,
    OBSERVER_THRESHOLD as SOURCE_OBSERVER_THRESHOLD,
} from 'SourceComponent/ProductList/ProductList.config';

// TODO: implement OBSERVER_THRESHOLD
export const OBSERVER_THRESHOLD = SOURCE_OBSERVER_THRESHOLD;

// TODO: implement INTERSECTION_RATIO
export const INTERSECTION_RATIO = SOURCE_INTERSECTION_RATIO;
