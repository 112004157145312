import {
    ProductListComponent as SourceProductListComponent,
} from 'SourceComponent/ProductList/ProductList.component';
import { ProductListComponentProps } from 'SourceComponent/ProductList/ProductList.type';
import { scrollToTop } from 'Util/Browser';
import { scrollToRef } from 'Util/Store/Browser';

import './ProductList.override.style';

/** @namespace Pwa/Component/ProductList/Component */
export class ProductListComponent extends SourceProductListComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps: ProductListComponentProps): void {
        const { isWidget, currentPage, device } = this.props;
        const { currentPage: prevCurrentPage } = prevProps;

        // Scroll up on page change, ignore widgets
        if (prevCurrentPage !== currentPage && !isWidget) {
            if (!device.isMobile) {
                scrollToTop({ behavior: 'smooth' });
            } else {
                scrollToRef('.Breadcrumbs');
            }
        }

        const { isInfiniteLoaderEnabled } = this.props;

        if (isInfiniteLoaderEnabled) {
            this.observePageChange();
        }
    }
}

export default ProductListComponent;
