import {
    ProductListPageComponent as SourceProductListPageComponent,
} from 'SourceComponent/ProductListPage/ProductListPage.component';

import './ProductListPage.override.style';

/** @namespace Pwa/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceProductListPageComponent {
    // TODO implement logic
}

export default ProductListPageComponent;
