import {
    CheckoutActionType,
    SetIsCheckoutAction,
    SetIsMenuAction,
    UpdateEmailAction,
    UpdateEmailAvailableAction,
    UpdateShippingFieldsAction,
} from './Checkout.type';

/** @namespace Pwa/Store/Checkout/Action/updateShippingFields */
export const updateShippingFields = (shippingFields: Record<string, unknown>): UpdateShippingFieldsAction => ({
    type: CheckoutActionType.UPDATE_SHIPPING_FIELDS,
    shippingFields,
});

/** @namespace Pwa/Store/Checkout/Action/updateEmail */
export const updateEmail = (email: string): UpdateEmailAction => ({
    type: CheckoutActionType.UPDATE_EMAIL,
    email,
});

/** @namespace Pwa/Store/Checkout/Action/updateEmailAvailable */
export const updateEmailAvailable = (isEmailAvailable: boolean): UpdateEmailAvailableAction => ({
    type: CheckoutActionType.UPDATE_EMAIL_AVAILABLE,
    isEmailAvailable,
});

/** @namespace Pwa/Store/Checkout/Action/setIsCheckout */
export const setIsCheckout = (isCheckout: boolean): SetIsCheckoutAction => ({
    type: CheckoutActionType.SET_IS_CHECKOUT,
    isCheckout,
});

/** @namespace Pwa/Store/Checkout/Action/setIsMenu */
export const setIsMenu = (isMenu: boolean): SetIsMenuAction => ({
    type: CheckoutActionType.SET_IS_MENU,
    isMenu,
});
